import React from 'react';
import styled from "styled-components";
import logo from "../static/images/logo.png"

const StyledNavBar = styled.div`
  padding: 20px;

  .nav-logo {
    width: 70px;
  }
`

const NavBar = () => {
    return (
        <StyledNavBar>
            <div className="nav-logo">
                <img src={logo} alt="logo"/>
            </div>
        </StyledNavBar>
    );
};

export default NavBar;