export const dark = {
    color: {

    },
    size: {

    },
    font: {

    }
}

export const light = {
    color: {

    },
    size: {

    },
    font: {

    }
}