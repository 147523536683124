import React from 'react'
import styled, {ThemeProvider} from "styled-components"
import {dark, light} from "../theme/Theme"
import GlobalStyle from "../theme/GlobalStyle"

const StyledAppContainer = styled.div`

`

const AppContainer = ({children}) => {
    return (
        <ThemeProvider theme={dark}>
            <GlobalStyle/>
            <StyledAppContainer>
                {children}
            </StyledAppContainer>
        </ThemeProvider>
    )
}

export default AppContainer