import React from 'react';
import styled from "styled-components";
import synology from "../static/images/cards/nas.png"
import photo from "../static/images/cards/photo.png"
import download from "../static/images/cards/download.png"
import plex from "../static/images/cards/plex.png"
import audio from "../static/images/cards/audio.png"
import dbeaver from "../static/images/cards/dbeaver.png"
import file from "../static/images/cards/file.png"
import bg from "../static/images/bg.jpg"
import NavBar from "../components/NavBar";

const StyledHomePage = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px;
  height: fit-content;
  margin: 20px;
  border-radius: 10px;
  background-color: rgba(79, 79, 80, 0.5);
  backdrop-filter: blur(4px);

  &:hover {
    background-color: rgba(79, 79, 80, 0.6);

    .card-name {
      color: #bdecbd;
    }
  }


  .card-body {
    height: 220px;
  }

  .card-name {
    color: aliceblue;
    font-weight: bold;
    font-size: 20px;
    width: 160px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    padding-bottom: 4px;
  }
`

const Home = () => {
    return (
        <StyledHomePage>
            <NavBar/>
            <CardWrapper>
                <Card>
                    <a href="https://protocloud.synology.me:5001" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={synology} alt="synology"/>
                        </div>
                        <div className="card-name">Synology Nas</div>
                    </a>
                </Card>

                <Card>
                    <a href="https://protocloud.synology.me/photo" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={photo} alt="photo"/>
                        </div>
                        <div className="card-name">Synology Photo</div>
                    </a>
                </Card>

                <Card>
                    <a href="https://protocloud.synology.me/download" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={download} alt="download"/>
                        </div>
                        <div className="card-name">Synology Download</div>
                    </a>
                </Card>

                <Card>
                    <a href="http://protocloud.synology.me:32400/web" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={plex} alt="plex"/>
                        </div>
                        <div className="card-name">Plex</div>
                    </a>
                </Card>

                <Card>
                    <a href="https://protocloud.synology.me/audio" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={audio} alt="audio"/>
                        </div>
                        <div className="card-name">Audio Station</div>
                    </a>
                </Card>

                <Card>
                    <a href="https://dbeaver.vitaliisili.com" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={dbeaver} alt="dbeaver"/>
                        </div>
                        <div className="card-name">DBeaver</div>
                    </a>
                </Card>

                <Card>
                    <a href="https://protocloud.synology.me/file" target="_blank" rel="noopener noreferrer">
                        <div className="card-body">
                            <img src={file} alt="file"/>
                        </div>
                        <div className="card-name">File Station</div>
                    </a>
                </Card>
            </CardWrapper>
        </StyledHomePage>
    );
};

export default Home;